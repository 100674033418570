import {createGlobalStyle} from 'styled-components';

export interface Font {
    fontFamily: string;
    fontNormal?: string;
    fontBold?: string;
    fontItalic?: string;
    isSelected?: boolean;
    isCustom?: boolean;
}

const FontFacesGlobalStyle = createGlobalStyle<{$font?: Font}>`
    ${({$font, theme}) => {
        const font = $font || theme.typography.fontStyle;
        const version = (Math.random() + 1).toString(36).substring(7);

        return `
        @font-face {
            font-family: '${font.fontFamily}';
            src: url('${
                font.fontNormal
            }?v=${version}'); // cache busting as directory and name both does not change when new font is uploaded
            // ideally we need to persist this version in the database and update it when new font is uploaded
            // as random string may be re-generated which will again show old cached font in the ui
            font-weight: normal;
            font-style: normal;
        }
        
        ${
            font.fontBold
                ? `@font-face {
            font-family: '${font.fontFamily}';
            src: url('${font.fontBold}');
            font-weight: bold;
        }`
                : ''
        }

        ${
            font.fontItalic
                ? `@font-face {
            font-family: '${font.fontFamily}';
            src: url('${font.fontItalic}');
            font-weight: bold;
        }`
                : ''
        }
    `;
    }}
`;

export default FontFacesGlobalStyle;
