import React from 'react';
import {Loader} from 'shared/helpers';
import {
    DefaultTheme,
    ThemeProvider as ThemeProviderStyled,
} from 'styled-components';
import {GlobalStyle} from 'theme';
import FontFacesGlobalStyle from 'theme/FontGlobalStyle';

export interface ThemeProviderProps {
    children: React.ReactNode;
    theme: DefaultTheme;
    isLoading: boolean;
    withGlobalStyle?: boolean;
    isCustomFont: boolean;
}

const ThemeProvider = ({
    children,
    isLoading,
    theme,
    isCustomFont,
    // we will probably need this later in admin and manu dashboard as well.
    // but for not not required
    withGlobalStyle = true,
}: ThemeProviderProps) => {
    if (isLoading) {
        return <Loader loader={true} />;
    }

    return (
        <ThemeProviderStyled theme={theme}>
            {isCustomFont ? <FontFacesGlobalStyle /> : null}
            {withGlobalStyle ? <GlobalStyle /> : null}
            {children}
        </ThemeProviderStyled>
    );
};

export default ThemeProvider;
