import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    ${({theme}) => `
        body,
        html {
            margin: 0;
            padding: 0;
        }

        html,
        body,
        #root {
            height: 100%;
        }

        body,
        html,
        h1,
        h2,
        h3,
        h4,
        h5,
        div,
        span,
        label,
        input,
        select,
        p,
        a,
        li,
        th,
        td,
        i,
        section,
        aside,
        header,
        footer,
        nav,
        strong,
        text {
            font-family: '${theme.typography.fontStyle.fontFamily}';
        }

        h1 {
            background: ${theme.colors.primary.main};
            border-radius: ${theme.borderRadius.header.primary}px;
            padding: ${theme.spacing(0.625)};
            color: #fff;
            font-weight: bold;
            display: flex;
            align-items: center;
            font-size: 1rem;
            margin: 15px 0;
            border-bottom-right-radius: 0 !important;
            box-sizing: border-box;
            overflow: hidden;
            position: relative;
            
            img {
                width: ${theme.sizes.image.header.primary};
            }
        
            label {
                flex: 2;
                text-align: center;
                margin: 0;
            }
        }

        h2 {
            background: ${theme.colors.primary.main};
            border-radius: ${theme.borderRadius.header.secondary}px;
            padding: ${theme.spacing(0.625)};
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            box-sizing: border-box;
            font-weight: bold;
            font-size: 0.85rem;
            text-align: center;
            width: 50%;
            margin: 15px 0 2px 0;
        }
        
        h5 {
            font-weight: bold;
            color: #204381;
        }
        
        #cbc-job-form > div ::placeholder {
            font-style: italic;
            opacity: 0.4 !important;
        }
        
        .primary-colour {
            color: ${theme.colors.primary.main};
        }

        .container {
            flex: 1;
        }
        
        .updates {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 0.5rem;
            max-height: 60px;
        }
        
        .contact-info {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1rem;
            max-height: 60px;
        }
        
        .loading-screen {
            position: fixed;
            opacity: 0.5;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            text-align: center;
            padding-top: 300px;
            box-sizing: border-box;
            z-index: 5;
            background: rgba(255, 255, 255, 0.35);
        }
        
        .loading-absolute {
            position: absolute !important;
            padding-top: 40% !important;
            &.pt-small {
                padding-top: 15% !important;
            }
        }
        
        header {
            box-shadow: 0 0 7px 0px #999999;
            position: relative;
            z-index: 4;
        }
    `}
`;

export default GlobalStyle;
