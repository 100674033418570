import produce from 'immer';
import {DefaultTheme} from 'styled-components';
import {BrandingInformation} from 'theme/store/themeApi';

export const getBrandedTheme = (
    brandingData: BrandingInformation,
    theme: DefaultTheme
) => {
    return produce(theme, (draft) => {
        draft.colors = {
            ...draft.colors,
            ...brandingData.colours,
        };

        const {branding} = brandingData || {};

        if (branding) {
            const {
                is_custom_font: isCustomFont,
                font_normal: fontNormal,
                font_bold: fontBold,
                font_italic: fontItalic,
            } = branding;

            if (isCustomFont) {
                draft.typography.fontStyle = {
                    fontFamily: 'Custom Font',
                    fontNormal: fontNormal,
                    fontBold: fontBold || fontNormal,
                    fontItalic: fontItalic || fontNormal,
                };
            } else {
                draft.typography.fontStyle = {
                    fontFamily:
                        fontNormal || draft.typography.fontStyle.fontFamily,
                    fontNormal:
                        fontNormal || draft.typography.fontStyle.fontNormal,
                    fontBold: fontBold || draft.typography.fontStyle.fontBold,
                    fontItalic:
                        fontItalic || draft.typography.fontStyle.fontItalic,
                };
            }
        }
    });
};
