import {LoginBackground} from 'assets';
import {DefaultTheme} from 'styled-components';

export const DEFAULT_FONT = 'Poppins';
export const DEFAULT_SPACING = 8;

export const baseTheme: DefaultTheme = {
    mode: 'LIGHT',
    colors: {
        primary: {
            main: '#204380',
        },
        secondary: {
            main: '#1793cd',
        },
        text: {
            main: '#000',
        },
        buttonText: {
            main: '#000',
        },
        headerColour: {
            main: '#deeaf8',
        },
        tableHover: {
            main: '#a2d4ec',
        },
        tableRowPrimary: {
            main: '#deeaf8',
        },
        tableRowSecondary: {
            main: '#fff',
        },
        tableTextColor: {
            main: '#000',
        },
        menuPrimaryColor: {
            main: '#37383a',
        },
        menuSecondaryColor: {
            main: '#000',
        },
        primaryIconColor: {
            main: '#204381',
        },
        secondaryIconColor: {
            main: '#1793cd',
        },
        navigationTextColor: {
            main: '#204381',
        },
        cartIconColor: {
            main: '#204381',
        },
        headerIconColor: {
            main: '#204381',
        },
        headerNavigationColor: {
            main: '#204381',
        },
        loginHeaderColor: {
            main: '#efefef',
        },
        mobileMenuColor: {
            main: '#f4f3f2',
        },
        error: {
            main: '#f8d7da',
        },
        errorLabel: {
            main: '#c92434',
        },
    },
    typography: {
        fontStyle: {
            fontFamily: String(DEFAULT_FONT),
            fontNormal: String(DEFAULT_FONT),
            fontBold: String(DEFAULT_FONT),
            fontItalic: String(DEFAULT_FONT),
        },
        fontSize: {
            p: '12px',
            body: '11.2px',
            h1: '22px',
            h2: '18px',
            h3: '16px',
            h4: '13px',
            h5: '12px',
            subtitle: '10.5px',
        },
    },
    zIndex: {
        nav: 100,
        card: 101,
        notification: 200,
        tooltip: 201,
        popup: 300,
    },
    defaultSpacing: DEFAULT_SPACING,
    borderRadius: {
        default: 8,
        rounded: 30,
        header: {
            primary: 20,
            secondary: 10,
        },
    },
    breakpoints: {
        mobile: 480,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
    },
    sizes: {
        image: {
            titleButton: 30,
            header: {
                primary: 30,
            },
        },
    },
    spacing: (v1: number, v2?: number, v3?: number, v4?: number) => {
        let value = `${DEFAULT_SPACING}px`;

        if (!v2 && !v3 && !v4) value = `${v1 * DEFAULT_SPACING}px`;

        return value;
    },
    device: {},
    images: {
        loginBackground: String(LoginBackground),
    },
};
