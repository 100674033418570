import {COLOR_MAP} from 'theme/store/themeApi';
import {hexToRgb} from 'shared/helpers';
import {Branding} from 'store/customer/brandingSlice';
import {Colours} from 'theme/types';

const setTitle = (branding: Branding) => {
    document.title = branding.branding_manufacturer_name;

    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (favicon) {
        favicon.href = branding.branding_manufacturer_favicon;
    }
};

// NOTE: we are not yet getting rid of this yet
// as a lot of the code is still using this
// we will remove this once we have refactored
// most of our components to use styled-components
const setColors = (colors: Colours) => {
    const root = document.documentElement;

    Object.keys(colors).forEach((key) => {
        const cssVarKey = COLOR_MAP[String(key)];
        const colorKey = key as keyof Colours;
        // disabling generic object injection sink below as key only comes
        // from a known map
        // eslint-disable-next-line security/detect-object-injection
        const color = colors[colorKey];
        const colorRGB = hexToRgb(color.main) as string;

        root.style.setProperty('--' + cssVarKey, colorRGB);
    });
};

export const setTitleAndBranding = (branding: Branding, colors?: Colours) => {
    setTitle(branding);

    if (colors) {
        setColors(colors);
    }
};
